/* global event */
/* eslint-disable */

 onload = onresize = function () {
    const cols = document.querySelectorAll('[data-col]');
    let encountered = [];

    for (let i = 0; i < cols.length; i++) {
        const attr = cols[i].getAttribute('data-col');

        if (encountered.indexOf(attr) === -1) {
            encountered.push(attr)
        }
    }

    for (let set = 0; set < encountered.length; set++) {
        const col = document.querySelectorAll('[data-col="' + encountered[set] + '"]');
        let group = [];

        for (let i = 0; i < col.length; i++) {
            col[i].style.height = 'auto';

            group.push(col[i].scrollHeight)
        }

        for (let i = 0; i < col.length; i++) {
            col[i].style.height = Math.max.apply(Math, group) + 'px'
        }
    }
};