const toggleMenu = elClass => {
	const toggleTrigger = document.querySelectorAll(`.${elClass}`);

	if (toggleTrigger.length === 0) {
		return;
	}

	let dataTargetMenu;
	let target;

	[].forEach.call(toggleTrigger, el => {
		el.addEventListener('click', e => {
			e.preventDefault();
			const _this = e.target;

			dataTargetMenu = _this.getAttribute('data-target-menu');
			target = document.getElementById(dataTargetMenu);

			if (!target) {
				return;
			}

			_this.classList.toggle('-active');

			target.classList.toggle('-opened');
		});
	});
};

export default toggleMenu;
