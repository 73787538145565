const toggleBox = () => {
	const toggleTriggerers = document.querySelectorAll('[data-toggle]');

	if (toggleTriggerers.length === 0) {
		return;
	}

	let toggleTarget;
	let toggleTriggererData;

	[].forEach.call(toggleTriggerers, el => {
		el.addEventListener('click', () => {
			toggleTriggererData = el.getAttribute('data-toggle');
			toggleTarget = document.querySelector(`.${toggleTriggererData}`);

			if (!toggleTarget) {
				return;
			}

			const isHidden = toggleTarget.style.display === 'none';

			if (isHidden) {
				toggleTarget.style.display = 'block';
				return;
			}

			toggleTarget.style.display = 'none';
		});
	});
};

export default toggleBox;
