/* eslint-disable no-undef */
import '../polyfills/resize';
import '../../../../node_modules/sticky-sidebar/dist/sticky-sidebar.js';

export default function initStickySidebar() {
	/*
	if (window.innerWidth >= 1520) {
		new StickySidebar('.js_sticky-sidebar', {
			containerSelector: '#content-container',
			innerWrapperSelector: '.sidebar-inner',
			topSpacing: 15,
			bottomSpacing: 15,
			minWidth: 105,
			resizeSensor: true
		});
	}

	 */
	new StickySidebar('.arrows', {
		containerSelector: '#table-content',
		innerWrapperSelector: '.arrows__inner',
		topSpacing: 40,
		bottomSpacing: 40,
		resizeSensor: true
	});
}
