/* global require */
/* global $ */
import flatPickr from 'flatpickr';
import {Czech} from 'flatpickr/dist/l10n/cs.js';

const targetDay = new Date();
targetDay.setDate(targetDay.getDate() + 2);
targetDay.setHours(0, 0, 0, 0);


const options = {
	allowInput: true,
	time_24hr: true,
	locale: Czech,
	dateFormat: 'd.m.Y',
	disableMobile: 'true',
	minuteIncrement: 1
};

export function initDatepicker(elClass) {
	flatPickr(`.${elClass}`,
		options);
}

export function initDatepickerFromDate(elClass) {
	flatPickr(`.${elClass}`,
		{
			time_24hr: true,
			locale: Czech,
			dateFormat: 'd.m.Y'
		});
}

export function initDatepickerToDate(elClass) {
	flatPickr(`.${elClass}`,
		{
			time_24hr: true,
			locale: Czech,
			dateFormat: 'd.m.Y'
		});
}

export function initRangeDatepicker(elClass) {
	flatPickr(`.${elClass}`,
		{
			...options,
			mode: 'range',
			defaultDate: ['today', targetDay],
			onChange(selectedDates) {
				const _this = this;
				const dateArr = selectedDates.map(date => _this.formatDate(date, 'Y-m-d H:i:s'));
				$('#date_from').val(dateArr[0]);
				$('#date_to').val(dateArr[1]);
			}
		});
}

export function initTimepicker(elClass) {
	flatPickr(`.${elClass}`,
		{
			allowInput: true,
			enableTime: true,
			noCalendar: true,
			dateFormat: 'H:i',
			time_24hr: true
		});
}
