// import slider from './parts/slider';
import initTooltip from './parts/tooltip';
import pointerEventsDetection from './parts/pointerEventsDetection';
import dropDownTable from './parts/dropDownTable';
import toggleBox from './parts/toggle';
import toggleMenu from './parts/toggle-menu';
import {
	initDatepicker, initDatepickerFromDate, initDatepickerToDate, initRangeDatepicker, initTimepicker
} from './parts/datepicker';
import initLightBox from './parts/lightbox';
import initStickySidebar from './parts/stickySidebar';

const MESIK_SCRIPTS = {
	init() {
		initTooltip('tooltip');
		initTooltip('with-tooltip');
		pointerEventsDetection();
		dropDownTable.init();
		toggleBox();
		toggleMenu('js_toggle-menu');
		initDatepicker('js_datepicker');
		initDatepickerFromDate('js_datepickerFrom');
		initDatepickerToDate('js_datepickerTo');
		initRangeDatepicker('js_range-datepicker');
		initTimepicker('js_timepicker');
		initLightBox('gallery');
		initStickySidebar();
	}
};

document.addEventListener('DOMContentLoaded', () => {
	MESIK_SCRIPTS.init();
});
