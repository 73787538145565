/* eslint-disable no-param-reassign */

class DropDownTable {
	constructor(ddTableId, ddTriggerClass, activeClass) {
		this.ddTable = document.querySelector(`#${ddTableId}`);
		this.activeClass = activeClass;

		if (this.ddTable.length === 0) {
			return;
		}

		this.ddTriggerEl = this.ddTable.querySelectorAll(`.${ddTriggerClass}`);

		if (this.ddTriggerEl.length === 0) {
			return;
		}

		this.initDropDownTable();
	}

	initDropDownTable() {
		let dataTriggerAttr;
		let target;

		[].forEach.call(this.ddTriggerEl, el => {
			el.addEventListener('click', e => {
				e.preventDefault();

				if (!el.parentElement.parentElement.parentElement.classList.contains(this.activeClass)) {
					this.removeAllActiveClasses();
					this.addActiveClass(el, dataTriggerAttr, target);
					return;
				}

				this.removeActiveClass(el, dataTriggerAttr, target);
			});
		});
	}

	removeAllActiveClasses() {
		const activeElements = this.ddTable.querySelectorAll(`.${this.activeClass}`);

		if (activeElements.length === 0) {
			return;
		}

		[].forEach.call(activeElements, elem => {
			elem.classList.toggle(this.activeClass);
		});
	}

	addActiveClass(trigger, dataTriggerAttr, target) {
		dataTriggerAttr = trigger.getAttribute('data-dd-trigger');
		target = this.ddTable.querySelector(`[data-dd-target="${dataTriggerAttr}"]`);

		if (!target) {
			return;
		}

		target.classList.add(this.activeClass);
		trigger.parentElement.parentElement.parentElement.classList.add(this.activeClass);
	}

	removeActiveClass(trigger, dataTriggerAttr, target) {
		dataTriggerAttr = trigger.getAttribute('data-dd-trigger');
		target = this.ddTable.querySelector(`[data-dd-target="${dataTriggerAttr}"]`);

		if (!target) {
			return;
		}

		target.classList.remove(this.activeClass);
		trigger.parentElement.parentElement.parentElement.classList.remove(this.activeClass);
	}
}

export default {
	init() {
		const ddTables = document.querySelectorAll('.js_dd-table');
		let ddTableId;

		if (ddTables.length === 0) {
			return;
		}

		[].forEach.call(ddTables, table => {
			ddTableId = table.getAttribute('id');

			new DropDownTable(ddTableId, 'js_dd-trigger', '-active');
		});
	}
};
