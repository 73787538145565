import tippy from 'tippy.js';

export default function initTooltip(elClass, delay = 40, duration = 300) {
	tippy(`.${elClass}`, {
		delay,
		arrow: true,
		size: 'large',
		duration,
		animation: 'shift-toward',
		maxWidth: 500
	});
}
